// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../styleguide/components/Paragraph/P.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Api_User from "../../../api/users/Api_User.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Routes_User from "../../../routes/common/Routes_User.res.js";
import * as $$Notification from "../../../bindings/Notification.res.js";
import * as ResponseMessage from "../../../styleguide/forms/ResponseMessage/ResponseMessage.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ReactNotifications from "react-notifications";
import * as UserForgotPasswordCss from "./UserForgotPasswordCss.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";

var validators = {
  email: {
    strategy: "OnFirstSuccessOrFirstBlur",
    validate: (function (param) {
        var email = param.email;
        if (email === "") {
          return {
                  TAG: "Error",
                  _0: "Email is required"
                };
        } else {
          return {
                  TAG: "Ok",
                  _0: email
                };
        }
      })
  }
};

function initialFieldsStatuses(_input) {
  return {
          email: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: {
            email: "Pristine"
          },
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match = fieldsStatuses.email;
  var match$1;
  match$1 = typeof match !== "object" ? validators.email.validate(input) : match._0;
  if (match$1.TAG === "Ok") {
    return {
            TAG: "Valid",
            output: {
              email: match$1._0
            },
            fieldsStatuses: {
              email: {
                TAG: "Dirty",
                _0: match$1,
                _1: "Shown"
              }
            },
            collectionsStatuses: undefined
          };
  } else {
    return {
            TAG: "Invalid",
            fieldsStatuses: {
              email: {
                TAG: "Dirty",
                _0: match$1,
                _1: "Shown"
              }
            },
            collectionsStatuses: undefined
          };
  }
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurEmailField" :
                  var result = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.email, validators.email, (function (status) {
                          return {
                                  email: status
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateEmailField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput, state.fieldsStatuses.email, state.submissionStatus, validators.email, (function (status) {
                                    return {
                                            email: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: {
                                email: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: {
                                email: "Pristine"
                              },
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateEmail: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateEmailField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurEmail: (function () {
              dispatch("BlurEmailField");
            }),
          emailResult: Formality.exposeFieldResult(state.fieldsStatuses.email),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.email;
              if (typeof tmp !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var ForgotPasswordComponentForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function UserForgotPassword$default(props) {
  var initialInput = React.useMemo((function () {
          return {
                  email: ""
                };
        }), []);
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ResponseMessage.make, {
                  messageType: "Error",
                  children: field._0,
                  className: "mt-1"
                });
    } else {
      return null;
    }
  };
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_User.forgotPassword({
                    user: {
                      email: output.email.trim()
                    }
                  }, "/users/password"), (function (x) {
                  if (x.TAG === "Ok") {
                    $$Notification.NotificationManager.success("Password instructions sent successfully", "", 5000);
                    setTimeout((function () {
                            Url.visit("/users/sign_in");
                          }), 1000);
                    return ;
                  }
                  $$Notification.NotificationManager.error("Something went wrong", "", 5000);
                  setTimeout(cb.reset, 1000);
                }));
        }));
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(ReactNotifications.NotificationContainer, {}),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("form", {
                            children: [
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs(H1.make, {
                                            children: [
                                              "Forgot your ",
                                              JsxRuntime.jsx("span", {
                                                    children: "password?"
                                                  })
                                            ]
                                          }),
                                      JsxRuntime.jsx(P.make, {
                                            children: "Enter your email address below and we'll get you back on track."
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Label.make, {
                                            forId: "forgotPassword-form--email",
                                            children: "Email adress"
                                          }),
                                      JsxRuntime.jsx(TextField.make, {
                                            id: "forgotPassword-form--email",
                                            type_: "Text",
                                            value: form.input.email,
                                            placeholder: "Enter your email",
                                            onBlur: (function (param) {
                                                form.blurEmail();
                                              }),
                                            onChange: (function ($$event) {
                                                form.updateEmail((function (param, value) {
                                                        return {
                                                                email: value
                                                              };
                                                      }), $$event.target.value);
                                              })
                                          }),
                                      fieldError(form.emailResult)
                                    ]
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx("a", {
                                                  children: "Already know your password? Sign In",
                                                  className: UserForgotPasswordCss.linkMuted,
                                                  href: Routes_User.signIn
                                                })
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(Button.make, {
                                                  size: "MD",
                                                  color: "Orange",
                                                  disabled: form.submitting,
                                                  busy: form.submitting,
                                                  submit: true,
                                                  children: "Request reset link"
                                                })
                                          })
                                    ],
                                    className: UserForgotPasswordCss.flexContainer
                                  })
                            ],
                            className: UserForgotPasswordCss.formContainer,
                            onSubmit: (function ($$event) {
                                $$event.preventDefault();
                                form.submit();
                              })
                          }),
                      className: UserForgotPasswordCss.container
                    })
              ],
              className: UserForgotPasswordCss.forgotPasswordContainer
            });
}

var Css;

var $$default = UserForgotPassword$default;

export {
  Css ,
  ForgotPasswordComponentForm ,
  $$default as default,
}
/* P Not a pure module */
